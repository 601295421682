/** @jsx jsx */
import { jsx, Container, Styled } from "theme-ui"
import { useTrail } from "react-spring"
import Layout from "./layout"
import { ChildImageSharp } from "../types"
import ProjectItem from "./project-item"

type ProjectsProps = {
  projects: {
    color: string
    slug: string
    title: string
    service: string
    client: string
    cover: ChildImageSharp
  }[]
}

const Projects = ({ projects }: ProjectsProps) => {

  var narrowWindow = typeof window === 'undefined'? false : window.innerWidth < 800;

  const trail = useTrail(projects.length, {
    from: { height: `0%`, width: `0%` },
    to: { height: `100%`, width: `100%` },
  })

  if (projects.length === 0) {
    return (
      <Layout>
        <Container>
          <Styled.p>
            Hi!{` `}
            <span role="img" aria-label="Wave emoji">
              👋
            </span>
            {` `}
            <br />
            Thanks for using <b>@lekoarts/gatsby-theme-emma</b>. You currently don't have any content in your{` `}
            <i>projects</i> folder - that's why this page displays a placeholder text. Head over to the{` `}
            <Styled.a href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-emma">
              README
            </Styled.a>
            {` `}
            to learn how to setup them.
          </Styled.p>
          <Styled.p>
            <b>TL;DR:</b> <br />
            The starter automatically created the folder <code>content/projects</code>. Go into this folder, create a
            new folder called <code>example</code> and create an <code>index.mdx</code> file there and place an image.
            Edit the frontmatter like described in the{` `}
            <Styled.a href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-emma">
              README
            </Styled.a>
            .
          </Styled.p>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <Container
        sx={{
          alignItems: `center`,
          display: `block`,
          width: [ `100%`, `100%`, `75%`, `50%` ],//`100%`,
          // height: `100%`,
          flex: 1,
          justifyContent: `center`,
          textAlign: `center`,
          // order: 4,
          // px: `20%`,
          // paddingRight: `2px`,
          // textAlignLast: `center`,
        }}
      >We make Games and Apps. Ranging from serious and educational, mobile and web to full blown entertainment titles.<br/> We develop games and apps for Windows, Mac, Linux, iOS and Android.<br/> We have done worked together with clients in the areas of Healthcare, Education and Culture.
      </Container>
      <Container
        sx={{
          display: `grid`,
          gridTemplateColumns: `repeat(auto-fit, minmax(330px, 1fr))`,
          gridGap: `10px`,
          width: `90%`,
          // margin: 10,
          // height: `80% !important`,
          // padding: `-10px`,
          // background: `#ddd`,
          // pl: `5%`,
        }}
        >
      {trail.map((style, index) => (
        <ProjectItem style={style} node={projects[index]} key={projects[index].slug} />
      ))}
      </Container>
    </Layout>
  )
}

export default Projects
